<template>
  <div>
    <click-to-edit
      v-model="scale.unit"
      disabled
      placeholder=""
      @input="$emit('change', scale.unit)"
    ></click-to-edit>
  </div>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import ClickToEdit from "@/components/ClickToEdit";

export default {
  name: "card-unit-view",
  mixins: [dynamicScaleComponent],
  components: {ClickToEdit},

  props: {
    disabled:{type: Boolean, default: false}
  },

  data() {
    return {
      scale:{
        unit: null,
      },
    }
  },
  mounted() {

  },
  computed: {

  },
  methods: {

  }
}
</script>

<style>

</style>